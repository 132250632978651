<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ title[$i18n.locale] }}
      </h2>

      <a-row class="flex-no__wrap wanted-search-input-wrapper" type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="form"
      :loading="loading"
      :pagination="{ defaultTabPageSize: 1 }"
      :scroll="{ x: 1500, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <router-link
          :to="{
            name: 'video-list',
            params: {
              cat_id: item.id
            }
          }"
          class="td-post-item"
          tag="span"
        >
          <b>{{ item.id }}</b>
        </router-link>
      </template>

      <template slot="name" slot-scope="item">
        <router-link
          :to="{
            name: 'video-list',
            params: {
              cat_id: item.id
            }
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.title || "-" }}
        </router-link>
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button
            type="primary"
            class="edit-btn"
            @click="editOpenOrgType(item.id)"
          >
            <!--{{ $t("Edit") }}-->
            <a-icon type="edit" class="action-btns" />
          </a-button>
          <a-popconfirm
            cancel-text="Нет"
            ok-text="Да"
            title="Вы действительно хотите удалить?"
            @confirm="removeItem(item.id)"
          >
            <a-button type="danger">
              <a-icon class="action-btns" type="delete" />
              <!--{{ $t("Delete") }}-->
            </a-button>
          </a-popconfirm>
        </a-row>
      </template>
    </a-table>

    <a-modal
      :visible="modalVisible"
      title="Добавить"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Название:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="formOrgType[`title_` + langTab]"
            placeholder="Введите название"
          />
        </a-col>
      </a-row>
      <a-row class="sm-mb" align="middle">
        <a-col :span="6" class="form_title"> Дата публикации: </a-col>
        <a-col :span="12">
          <input v-model="form.pub_date" type="datetime-local" />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
        <a-button type="primary" @click="saveOrgType"> Сохранить </a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      title: {
        oz: "Видеогаллерея",
        uz: "Videogalereya",
        ru: "Видеогаллерея",
        en: "Video gallery"
      },
      formOrgType: {
        title_uz: "",
        title_oz: "",
        title_qr: "",
        title_en: "",
        title_ru: ""
      },
      modalVisible: false,
      langTab: $langPrefix,
      loading: false,
      columns: [
        {
          title: "ID",
          key: "id",
          width: "70px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          width: "40%",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "Slug",
          key: "slug",
          width: "40%",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ]
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const { data } = await this.$api.get(
          "/admin/gallery/video-gallery-category/list/"
        )
        this.form = data
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
      this.loading = false
    },
    async saveOrgType() {
      try {
        if (this.formOrgType.id) {
          await this.$api.patch(
            `/admin/gallery/video-gallery-category/${this.formOrgType.id}/update/`,
            this.formOrgType
          )
          this.$message.success("Успешно сохранено")
        } else {
          await this.$api.post(
            "/admin/gallery/video-gallery-category/create/",
            this.formOrgType
          )
          this.$message.success("Успешно добавлено")
        }
        await this.fetchData()
        this.modalVisible = false
      } catch (error) {
        this.$message.error("Ошибка при добавлении")
        console.error(error)
        this.modalVisible = false
      }
    },
    async editOpenOrgType(value) {
      const { data } = await this.$api.get(
        `/admin/gallery/video-gallery-category/${value}/update/`
      )
      this.formOrgType = data
      this.modalVisible = true
    },
    async removeItem(id) {
      this.loading = true
      try {
        await this.$api.delete(
          `/admin/gallery/video-gallery-category/${id}/delete/`
        )
        await this.fetchData()
        this.$message.success("Категория успешно удалено")
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    openCreateModal() {
      this.modalVisible = true
      this.formOrgType = {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: ""
      }
    }
  }
}
</script>
<style scoped>
.wanted-search-input-wrapper {
  justify-content: flex-end;
}
.form_title {
  text-align: right;
  font-weight: 400;
}
</style>
